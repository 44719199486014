import { useEffect, useRef } from 'react';

/**
 *
 * @param callback  - to be executed given every delay ms
 * @param delay - time between executions in miliseconds
 * @returns clearInterval method
 */
const useInterval = (callback: () => void, delay: number) => {
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const clearInterval = () => {
    intervalRef.current && window.clearInterval(intervalRef.current);
  };

  useEffect(() => {
    const tick = () => savedCallback.current();
    intervalRef.current = setInterval(tick, delay);
    return () => clearInterval();
  }, [delay]);

  return clearInterval;
};

export default useInterval;
