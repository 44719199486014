import type { ReactNode } from 'react';
import type { StagePlusIcon } from '@stageplus/icons/react';
import CloseIcon from '@stageplus/icons/react/close';
import NotificationInfoIcon from '@stageplus/icons/react/notification';
import NotificationErrorIcon from '@stageplus/icons/react/notification-error';
import NotificationSuccessIcon from '@stageplus/icons/react/notification-success';
import clsx from 'clsx';
import { ButtonIconOnly } from 'src/components/buttons/icon-button';
import ContainerGrid from 'src/components/container-grid';
import { NotificationType } from 'src/hooks/use-notification';
import useTranslate from 'src/hooks/use-translate';
import { FunctionalComponentWithChildren } from 'src/types';

type NotificationToastProps = {
  /** The title of the notification */
  title?: string;
  /** The main message of the notification */
  text?: ReactNode;
  /**
   * The type of the notification, affecting icon etc.
   * @default 'info'
   */
  type?: NotificationType;
  /** An optional action UI for this notification, usually a link or a button */
  actionUI?: FunctionalComponentWithChildren;
  /** Whether to display the notification */
  open?: boolean;
  /** A handler function for when the notification is closed */
  onClose: () => void;
};

/**
 * A map of notification types and their respective icons
 */
const iconMap: { [key in NotificationType]: StagePlusIcon } = {
  info: NotificationInfoIcon,
  success: NotificationSuccessIcon,
  error: NotificationErrorIcon,
};

/**
 * Keep aria-live regions always mounted, and render appropriate types
 * of notifications:
 * - `polite` region for `info` and `success` messages, that don't need
 *   to interrupt ongoing announcements;
 * - `assertive` region for `error` messages, that DO need to interrupt
 *   ongoing announcements.
 */
function AriaLiveRegions({
  open,
  type,
  title,
  text,
}: Pick<NotificationToastProps, 'open' | 'type' | 'title' | 'text'>) {
  const content = (
    <>
      <h2>{title}</h2>
      <p>{text}</p>
    </>
  );
  return (
    <div className="sr-only">
      <div aria-live="polite">{open && (type === 'info' || type === 'success') && content}</div>
      <div aria-live="assertive">{open && type === 'error' && content}</div>
    </div>
  );
}

/**
 * A small notification 'toast' modal, used to notify user about a certain action
 *
 * @example
 * ```tsx
 * <NotificationToast
 *   title="Success"
 *   text="Your address has been successfully added"
 *   type="success"
 *   open={true}
 *   action={() => <Button>Undo</Button>}
 * />
 * ```
 */
export default function NotificationToast({
  open,
  title,
  text,
  type = 'info',
  actionUI: ActionUI,
  onClose,
}: NotificationToastProps) {
  const t = useTranslate();
  // get the icon for the notification type
  const Icon = iconMap[type];

  return (
    <>
      <AriaLiveRegions open={open} type={type} title={title} text={text} />
      <div className="pointer-events-none fixed inset-0 z-50 flex flex-wrap content-start">
        <ContainerGrid>
          <div
            className={clsx(
              'offset-header relative col-span-full mt-5 transform-gpu rounded-md bg-white text-highlightBlueC3 shadow-[0_12px_36px_rgba(0,0,0,0.25)] lg:col-span-6 lg:col-start-4 lg:mt-6 xl:mt-8',
              open ? 'pointer-events-auto md:animate-appear' : 'opacity-0 transition-opacity duration-1000',
            )}
            data-test="notification-toast"
            aria-hidden={!open}
          >
            <div className="p-4">
              <div className="flex items-start">
                <div className="shrink-0">
                  <Icon className="size-6 lg:size-8" />
                </div>
                <div className="ml-2 w-0 flex-1">
                  <h2 className="dg-text-medium-9">{title}</h2>
                  <p className="dg-text-regular-6 [&>strong]:dg-text-medium-6 text-deepBlueC4 text-opacity-70">
                    {text}
                  </p>
                  {ActionUI && <ActionUI />}
                </div>
                <div className="flex shrink-0">
                  <ButtonIconOnly
                    disabled={!open}
                    title={t('notification__close')}
                    icon={<CloseIcon />}
                    onClick={onClose}
                    className="-mr-2 -mt-2 size-6"
                  />
                </div>
              </div>
            </div>
          </div>
        </ContainerGrid>
      </div>
    </>
  );
}
