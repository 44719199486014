import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import useSWR from 'swr';
import { TextButton } from 'src/components/buttons/text-button';
import ControlledInputCheckbox from 'src/components/forms/elements/controlled-input-checkbox';
import ControlledInputText from 'src/components/forms/elements/controlled-input-text';
import Modal from 'src/components/modal';
import { useCancelationReasonsModal } from 'src/hooks/use-modal';
import useSdk, { SetCancelationReasonInput } from 'src/hooks/use-sdk';
import useTranslate from 'src/hooks/use-translate';
import { TranslationKeyCommon } from 'src/types';
import { captureException } from 'src/utilities/exceptions';

const translationKeys: TranslationKeyCommon[] = [
  'settings__cancelation_reasons_no_content',
  'settings__cancelation_reasons_too_expensive',
  'settings__cancelation_reasons_another_service',
  'settings__cancelation_reasons_no_time',
  'settings__cancelation_reasons_no_app',
  'settings__cancelation_reasons_other',
];

type TranslationKey = (typeof translationKeys)[number];

type FormValues = {
  [K in TranslationKey]: boolean;
} & { reason_other?: string };

/**
 * After a customer cancels their subscription, we show a modal with a form,
 * offering a list of reasons and a free text field. The cancellation should
 * already happen by this point and skipping or submitting the survey should
 * have no impact on the cancellation itself.
 */
export default function CancelationReasonsModal() {
  const { isOpen, close } = useCancelationReasonsModal();
  const { control, handleSubmit, formState, watch, reset } = useForm<FormValues>();
  const t = useTranslate();
  const sdk = useSdk();

  // Reset the form on modal open/close
  useEffect(() => reset(), [isOpen, reset]);

  const { data } = useSWR(
    // SWR key is set only when the modal is open, to avoid fetching the data when it's not needed
    isOpen ? 'cancelationReasons' : undefined,
    () => sdk.cancelationReasons(),
    // Disable revalidation because the data is static and we don't need to refetch it
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const onSubmit = useCallback<SubmitHandler<FormValues>>(
    async (data) => {
      const { reason_other: reasonOther, ...restReasons } = data;

      // The contract is to send the translation keys corresponding to the selected
      // reasons to the backend as "reasons" array, and the free text is sent
      //  as string in a separate field called "other".
      const input: SetCancelationReasonInput = {
        reasons: Object.entries(restReasons)
          .filter(([, isSelected]) => isSelected)
          .map(([reason]) => reason),
      };

      // Send other text only when other is checked
      if (reasonOther && restReasons.settings__cancelation_reasons_other) {
        input.other = reasonOther;
      }

      try {
        await sdk.setCancelationReason({ input });
      } catch (error) {
        // this survey should be unobtrusive, in the unlikely event the call
        // has failed, we will log it to sentry
        captureException(error);
      }

      close();
    },
    [close, sdk],
  );

  const isOtherOptionSelected = watch('settings__cancelation_reasons_other');

  return (
    <Modal open={isOpen} onClose={close} dataTest="cancelation-reasons-modal">
      <Modal.Content>
        <h1 className="dg-text-medium-2 py-6 text-center">{t('settings__cancelation_reasons_title')}</h1>
        <p className="dg-text-regular-5 mb-2 text-center opacity-70">{t('settings__cancelation_reasons_subtitle')}</p>
        <p className="dg-text-regular-5 mb-8 text-center opacity-70">
          {t('settings__cancelation_reasons_description')}
        </p>
        <form data-test="cancelation-reasons-form" onSubmit={handleSubmit(onSubmit)}>
          {data?.cancelationReasons.map((translationKey) => {
            return (
              <div className="mb-3" key={translationKey}>
                <ControlledInputCheckbox
                  label={t(translationKey as TranslationKeyCommon)}
                  defaultValue={false}
                  control={control}
                  name={translationKey as TranslationKey}
                />
              </div>
            );
          })}
          {isOtherOptionSelected && (
            <div className="dg-text-regular-5">
              <ControlledInputText
                name="reason_other"
                control={control}
                multiline
                label={t('settings__cancelation_reasons_other_field_text')}
                data-test="cancelation-reasons-other-textarea"
              />
            </div>
          )}

          <div className="flex justify-center space-x-4 pt-6">
            <TextButton variation="outline" onClick={close} fullWidth>
              {t('settings__cancelation_reasons_cancel')}
            </TextButton>
            <TextButton fullWidth type="submit" variation="secondary" loading={formState.isSubmitting}>
              {t('settings__cancelation_reasons_confirm')}
            </TextButton>
          </div>
        </form>
      </Modal.Content>
    </Modal>
  );
}
