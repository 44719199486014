import { useCallback, SyntheticEvent } from 'react';
import CloseIcon from '@stageplus/icons/react/close';
import clsx from 'clsx';
import { ButtonIconRound } from 'src/components/buttons/icon-button';
import ImageSquare from 'src/components/common/image-square';
import { SitePlayerControlsMini } from 'src/components/site-player-controls';
import { MiniPlayerProgress } from 'src/components/site-player-progress';
import usePictureInPicture from 'src/hooks/use-picture-in-picture';
import { usePlayerUrl } from 'src/hooks/use-player-url';
import useTranslate from 'src/hooks/use-translate';
import { usePlayback } from 'src/state/playback';
import { useVideoContext } from 'src/state/video';

/**
 * the site player when minified at the bottom of the page.
 * It uses the global playback state and video context to access the video element
 *
 * @component
 */
const SitePlayerMini = (): JSX.Element => {
  const t = useTranslate();

  // player URL operations
  const { activatePlayerUrl } = usePlayerUrl();

  // player queue operations
  const { clearQueue, currentMedia, playbackQueue } = usePlayback();
  const isAudio = currentMedia?.__typename === 'Album';
  const isLiveConcert = currentMedia?.__typename === 'LiveConcert';

  // the global video object
  const { videoRef, pause, isPlaying } = useVideoContext();

  // re-activate player url in order switch to the maxi player
  const reactivatePlayerUrl = useCallback(() => {
    currentMedia && void activatePlayerUrl(currentMedia, playbackQueue.activeItemId);
  }, [currentMedia, playbackQueue.activeItemId, activatePlayerUrl]);

  // handle a player closing
  const handleClose = useCallback(
    (event: SyntheticEvent) => {
      //  make sure the click is not propagating to other event handlers
      event.stopPropagation();
      //  pause the stream engine
      pause();
      // reset the playback state
      void clearQueue();
    },
    [pause, clearQueue],
  );

  // when the user clicks on the player
  const handleMiniPlayerClick = useCallback(() => {
    // re-open the maxi player
    reactivatePlayerUrl();
  }, [reactivatePlayerUrl]);

  // if the user restores from the picture-in-picture mode while in the mini player,
  // we should re-activate the main maxi player too
  usePictureInPicture({
    videoRef,
    onLeave: reactivatePlayerUrl,
  });

  // the close button that is shown in the mini player
  const ClosePlayerButton = () => (
    <div
      className={clsx(
        'absolute left-1/2 top-1/2 z-30 -ml-4 -mt-4 transform-gpu transition-opacity duration-300 group-hover:opacity-100',
        isPlaying ? 'opacity-0' : 'opacity-100',
      )}
    >
      <ButtonIconRound
        title={t('player__button_close')}
        icon={<CloseIcon className="text-mainBgBlueC2" />}
        onClick={handleClose}
        dataTest="close-player-button"
      />
    </div>
  );

  return (
    <div
      className="pointer-events-auto absolute inset-x-0 bottom-0 flex h-16 items-center justify-start lg:h-20"
      data-test="site-player-mini"
    >
      {isAudio ? (
        <div className="group relative size-16 shrink-0 lg:size-20">
          <div className="group relative h-full opacity-0"></div>
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="inline-block size-12 overflow-hidden rounded-lg lg:size-16">
              {currentMedia && (
                <ImageSquare width={64} height={64} src={currentMedia.pictures[0]?.url} alt={currentMedia.title} />
              )}
            </div>
          </div>
          <ClosePlayerButton />
        </div>
      ) : (
        <div className="group relative h-16 w-28 lg:h-20 lg:w-36">
          <div className="group relative h-full"></div>
          <ClosePlayerButton />
        </div>
      )}

      <div
        className="flex h-full grow cursor-pointer items-center bg-deepBlueC4 pl-4 lg:pl-6"
        onClick={handleMiniPlayerClick}
      >
        <SitePlayerControlsMini />
      </div>
      {!isLiveConcert && (
        <div className="absolute bottom-0 w-full">
          <MiniPlayerProgress />
        </div>
      )}
    </div>
  );
};

export default SitePlayerMini;
